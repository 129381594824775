import { Footer, Header, Helmet, SEO, useI18next } from '@/components'
import Empty from '@/components/Empty'
import { APIqueryCountry } from '@/components/EnquireForm/api'
import { Form, FormItem, FormItemProps } from '@/components/EnquireForm/Form'
import { GlobalAntdFormStyle } from '@/components/GlobalCSS/AntdFormStyle'
import Search from '@/components/Search'
import { Box, useMediaQuery } from '@mui/material'
// import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useRequest } from 'ahooks'
import { Button, Row } from 'antd'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Cookies from 'js-cookie'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import filterSvg from '../../../static/images/filter.svg'
import jiaobiaoSvg from '../../../static/images/jiaobiao.svg'

export default (props) => {
  const {} = useTranslation()
  const theme = useTheme()
  const { direction } = theme
  const { navigate } = useI18next()
  const pageLocale = JSON.parse(props.data.locales.edges[0].node.data)
  const commonLocale = JSON.parse(props.data.commonLocale.data)
  const seoLocale = JSON.parse(props.data.seoLocale.data)
  const isPc = useMediaQuery('@media (min-width: 800px)')
  const [form] = Form.useForm()
  const [selectedCallCode, setSelectedCallCode] = useState<string>()
  const callCodeRes = useRequest(APIqueryCountry, {
    onSuccess: (res) => {
      const list = res?.resultObject
      Array.isArray(list) && setSelectedCallCode(list?.[0]?.countryCallingCode)
    },
  })
  const [visible, setVisible] = useState(false)
  const initialState = { topic: 'ALL', country: 'ALL' }

  const [BlogCountry, setBlogCountry] = useState([])
  const [BlogCategory, setBlogCategory] = useState([])
  const [dataBlog, setDataBlog] = useState([])
  const [pageInfo, setPage] = useState({})
  const [searhParams, setSearchParams] = useState({
    titleOrBody: '',
    category: 'ALL',
    country: 'ALL',
    currentPage: 1,
  })

  const { enqueueSnackbar } = useSnackbar()

  const getBizDictData = async () => {
    const url = `/hermes/bizDictData/opitions/v2?typeCode=BlogCategory,BlogCountry`
    return window
      .fetch(url, {
        method: 'get',
        headers: {
          lang: Cookies.get('EXPRESS_LANG'),
        },
      })
      .then((res) => {
        return res.json()
      })
      .catch((err) => {
        return err
      })
  }

  const getBlogs = async (data = {}) => {
    if (data?.category === 'ALL') {
      delete data.category
    }
    if (data?.country === 'ALL') {
      delete data.country
    }

    const url = `/flash/show/getBlogs`
    return window
      .fetch(url, {
        method: 'POST',
        headers: {
          lang: Cookies.get('EXPRESS_LANG'),
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          currentPage: 1,
          showCount: 6,
          ...data,
        }),
      })
      .then((res) => {
        return res.json()
      })
      .catch((err) => {
        return err
      })
  }

  const { data, loading, run } = useRequest(getBizDictData, {
    manual: false,
    debounceInterval: 1000,
    throttleInterval: 1000,
    onSuccess: (res, params) => {
      if (res?.resultObject) {
        const data = res?.resultObject
        setBlogCountry(() => {
          return [{ key: 'ALL', value: 'ALL' }, ...data['BlogCountry']].map((item) => {
            return {
              ...item,
              label: item.value,
              value: item.key,
            }
          })
        })
        setBlogCategory(() => {
          return [{ key: 'ALL', value: 'ALL' }, ...data['BlogCategory']].map((item) => {
            return {
              ...item,
              label: item.value,
              value: item.key,
            }
          })
        })
      } else if (res?.status === 'success' && !res?.resultObject) {
        enqueueSnackbar('no data')
      } else {
        enqueueSnackbar(res.message || 'error')
      }
    },
  })

  const getListBlogs = useRequest(getBlogs, {
    onSuccess: (res) => {
      if (res?.resultObject) {
        const data = res?.resultObject
        setDataBlog(data.results)
        setPage(data.pagination)
      } else if (res?.status === 'success' && !res?.resultObject) {
        enqueueSnackbar('no data')
      } else {
        enqueueSnackbar(res.message || 'error')
      }
    },
  })

  const getText = (str) => {
    return str.replace(/<[^<>]+>/g, '').replace(/&nbsp;/gi, '')
  }

  const formList: FormItemProps[] = [
    {
      type: 'Select',
      name: 'country',
      label: '',
      required: true,
      ColProps: { md: 24, xs: 24 },
      data: BlogCountry,
      optionValue: 'value',
      optionLabel: 'label',
      SelectProps: {
        onSelect: (e) => {
          setSearchParams({
            ...searhParams,
            country: e === 'ALL' ? '' : e,
          })
          getListBlogs.run({
            ...searhParams,
            country: e === 'ALL' ? '' : e,
          })
        },
      },
    },
  ]

  useEffect(() => {
    document.querySelector('.banner')?.scrollIntoView({})
  }, [])

  return (
    <>
      <SEO
        title={seoLocale.blog.title}
        description={seoLocale.blog.description}
        pageLocale={pageLocale}
      />
      <Helmet>
        <meta
          name='keywords'
          content='Logistics blog, Shipping and logistics tips, Delivery blogs, iMile news, iMile updates'
        />

        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='iMile Delivery' />
        <meta property='og:url' content='https://www.imile.com/blog' />
        <meta
          property='og:image'
          content='https://www.imile.com/static/efd3abbbd3f1acb8607970a7952a48f0/8f71f/banner.webp'
        />
        <meta property='og:title' content='iMile Delivery Blog' />
        <meta
          property='og:description'
          content="Keep up-to-date on the latest updates in the logistics and shipping industry and what's new at iMile "
        />
      </Helmet>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      {/* banner*/}
      <Box
        className='banner'
        sx={{
          position: 'relative',
          height: ['100vh', '100vh', '520px', '760px'],
          background: '#095ef7',
          '.bg-img': {
            position: 'absolute',
            right: ['26px', '70px', '70px', '79px', '101px'],
            top: ['100px', '126px', '170px'],
            width: ['233px', '388px', '393px', '455px'],
            height: ['260px', '435px', '440px', '500px'],
          },
        }}
      >
        <img
          src='https://imile-minio-cdn.imile.com/imile-home/offical-web/blog/banner.png'
          alt='About iMile Delivery'
          className='bg-img'
        />
        {/* <StaticImage
          src='../../../static/images/blog/banner.png'
          alt='About iMile Delivery'
          objectFit='cover'
          loading='lazy'
          placeholder='blurred'
          className='bg-img'
        /> */}

        <Search
          buttonTxt={commonLocale.search}
          placeHolder={pageLocale.searchTips}
          homebannersx={{
            position: 'absolute',
            bottom: ['104px', 0],
            left: '50%',
            transform: 'translate(-50%)',
            bgcolor: 'unset',
          }}
          callback={(text) => {
            console.log(text, '0-0-0-0-')
            setSearchParams({
              ...searhParams,
              titleOrBody: text.trim(),
            })
            getListBlogs.run({
              ...searhParams,
              titleOrBody: text.trim(),
            })
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: ['60%', '75%', '50%'],
            transform: 'translate(0, -40%)',
            left: ['16px', '80px'],
            color: '#fff',
            marginInlineStart: 'auto',
            '.tit': {
              fontSize: 'var(--slogan-tit-font-size)',
              fontWeight: 800,
              maxWidth: [277, 320, 305, 400, 455],
            },
            '.desc': {
              letterSpacing: '1px',
              fontSize: 'var(--slogan-desc-font-size)',
              maxWidth: [323, 353, 355, 489],
            },
          }}
        >
          <h1 className='tit'>{pageLocale.Slogan}</h1>
          <div className='desc'>{pageLocale.SloganDesc}</div>
        </Box>
      </Box>

      {/* 筛选区域 */}
      {isPc ? (
        <>
          <GlobalAntdFormStyle />
          <Box
            className=''
            sx={{
              display: 'flex',
              py: ['72px'],
              px: 'calc(var(--page-px) + 20px)',
              maxWidth: 'var(--page-width)',
              margin: '0 auto',
              '.left': {
                display: 'flex',
                flex: 1,
                fontSize: ['16px'],
                color: '#5b5c73',
                height: '46px',
                lineHeight: '46px',
                '.item': {
                  cursor: 'pointer',
                  px: ['12px', '20px'],
                  '&-active': {
                    color: '#000417',
                    bgcolor: '#f6f8fb',
                    borderRadius: '4px',
                  },
                },
                '.selected': {
                  color: '#000417',
                  background: '#f6f8fb',
                  height: ['46px'],
                  lineHeight: ['46px'],
                  padding: '0 20px',
                },
              },
              '.right': {
                display: 'flex',
                alignItems: 'baseline',
                marginInlineStart: 'auto',
                width: ['400px'],
                '.MuiBox-root': {
                  flex: 1,
                },
                '.label': {
                  fontSize: [16],
                  borderRadius: direction === 'rtl' ? '0 8px 8px 0' : '8px 0 0 8px',
                  color: '#000417',
                  background: '#ebeff5',
                  lineHeight: '46px',
                  padding: '0 20px',
                },
              },
              '.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
                borderRadius:
                  direction === 'rtl' ? '4px 0 0 4px !important' : '0 4px 4px 0 !important',
                paddingTop: '2px',
              },
            }}
          >
            <div className='left'>
              {BlogCategory.map((oItem, index): any => (
                <div
                  className={`item ${oItem.value === searhParams.category ? 'item-active' : ''}`}
                  key={oItem.key}
                  onClick={() => {
                    setSearchParams({
                      ...searhParams,
                      category: oItem.value,
                    })
                    getListBlogs.run({
                      ...searhParams,
                      category: oItem.value,
                    })
                  }}
                >
                  {oItem.label}
                </div>
              ))}
            </div>
            <div className='right'>
              <div className='label'>Country</div>
              <Form form={form} name='form_in_modal'>
                <Row>
                  {formList.map((formItem) => (
                    <FormItem {...formItem} key={formItem.name} />
                  ))}
                </Row>
              </Form>
            </div>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              position: 'sticky',
              top: '55px',
              zIndex: 1,
              height: '56px',
              background: '#fff',
            }}
            onClick={() => {
              setVisible(!visible)
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                maxWidth: 'var(--page-width)',
                width: 'max-content',
                height: '36px',
                lineHeight: '36px',
                borderRadius: '8px',
                backgroundColor: 'rgba(240, 242, 250, 1)',
                px: '16px',
                my: '20px',
                marginInlineStart: 'auto',
                marginInlineEnd: '24px',
                color: '#000417',
                fontSize: '14px',
                fontWeight: 600,
                '.ic_img': {
                  width: '14px',
                  height: '14px',
                  marginInlineEnd: '6px',
                },
              }}
            >
              <img src={filterSvg} className='ic_img' alt='' />
              {pageLocale.filter}
            </Box>
          </Box>
          <Box
            sx={{
              position: 'sticky',
              top: '110px',
              width: 'auto',
              height: '350px',
              maxHeight: '300px',
              background: '#fff',
              display: visible ? 'block' : 'none',
              borderTop: '1px solid #e8eaf3',
              overflow: 'scroll',
              zIndex: 1,
              '.ant-btn': {
                minWidth: '160px',
                textAlign: 'center',
                padding: '6px 16px',
                fontSize: '16px',
                fontWeight: 'bold',
                height: '48px',
                background: '#f0f1f6',
                border: 'none',
              },
              '.ant-btn-primary': {
                background: '#095ef7',
              },
              '.content': {
                padding: '20px 16px',
              },
            }}
          >
            {[
              {
                title: 'Topic',
                type: 'category',
                list: BlogCategory,
              },
              {
                title: 'Country',
                type: 'country',
                list: BlogCountry,
              },
            ].map((item, index) => (
              <Box
                key={index}
                className='content'
                sx={{
                  '.tit': {
                    color: '#000417',
                    fontSize: '14px',
                    fontWeight: 600,
                    mb: '10px',
                  },
                  '.item': {
                    position: 'relative',
                    height: '40px',
                    lineHeight: '40px',
                    borderRadius: '6px',
                    color: '#5b5c73',
                    backgroundColor: '#f6f8fb',
                    textAlign: 'center',
                    '&.selected': {
                      backgroundColor: 'rgba(6, 44, 255, 0.1)',
                    },
                    '.ic_img': {
                      position: 'absolute',
                      right: 0,
                      bottom: 0,
                      width: '20px',
                    },
                  },
                }}
              >
                <div className='tit'>{item.title}</div>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                  {item.list?.map((o, _i) => (
                    <Grid item xs={2} sm={4} md={4} key={o.value}>
                      <div
                        className={`item ${
                          (item.type === 'category' && o.value === searhParams.category) ||
                          (item.type === 'country' && o.value === searhParams.country)
                            ? 'selected'
                            : ''
                        }`}
                        onClick={() => {
                          setSearchParams({
                            ...searhParams,
                            [item.type]: o.value,
                          })
                        }}
                      >
                        {o.label}
                        {(item.type === 'category' && o.value === searhParams.category) ||
                        (item.type === 'country' && o.value === searhParams.country) ? (
                          <img src={jiaobiaoSvg} className='ic_img' alt='' />
                        ) : null}
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))}
            <Box
              sx={{
                position: 'sticky',
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Button
                key='3'
                block
                onClick={() => {
                  getListBlogs.run({
                    ...searhParams,
                    category: 'ALL',
                    country: 'ALL',
                  })
                  setVisible(false)
                }}
              >
                {pageLocale.reset}
              </Button>
              <Button
                key='3'
                type='primary'
                block
                onClick={() => {
                  getListBlogs.run({
                    ...searhParams,
                  })
                  setVisible(false)
                }}
              >
                {pageLocale.search}
              </Button>
            </Box>
          </Box>
        </>
      )}

      {/* 博客文章 */}
      <Box
        sx={{
          position: 'relative',
          minHeight: ['426px'],
        }}
      >
        {dataBlog?.length > 0 ? (
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{
              width: '100% !important',
              px: 'var(--page-px)',
              maxWidth: 'var(--page-width)',
              mx: 'auto !important',
              mb: ['52px'],
            }}
          >
            {dataBlog.map((oItem, index): any => (
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                key={index}
                sx={{ px: '10px !important', cursor: 'pointer' }}
                onClick={() => {
                  navigate(`/blog/detail/${oItem.searchTitle.toLowerCase()}`, {
                    state: {
                      title: oItem.searchTitle.toLowerCase(),
                      id: oItem.id,
                    },
                  })
                }}
              >
                <Card
                  sx={{
                    position: 'relative',
                    maxWidth: 400,
                    m: '0 auto',
                    borderRadius: '24px',
                    fontSize: [16],
                    minHeight: [430, 480],
                    maxHeight: [430, 480],
                    background: '#F6F7FA',
                  }}
                >
                  <CardMedia
                    component='img'
                    image={oItem.coverImageUrl}
                    alt='green iguana'
                    sx={{
                      height: [206, 240],
                    }}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant='h5'
                      component='div'
                      sx={{
                        fontSize: [16, 20],
                        minHeight: [43, 58],
                        overflow: 'hidden',
                        color: '#000417',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        '-webkit-line-clamp': '2',
                        '-webkit-box-orient': 'vertical',
                        ':hover': {
                          color: '#095ef7',
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {oItem.tittle}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant='h5'
                      component='div'
                      sx={{
                        display: 'flex',
                        fontSize: [16],
                        fontFamily: 'unset',
                        fontWeight: 500,
                        color: '#5b5c73',
                        mt: ['24px'],
                        mb: ['12px'],
                      }}
                    >
                      <div>
                        {' '}
                        {`${
                          oItem.createDateFormart1.split('-')[2]
                        }/${+oItem.createDateFormart1.split('-')[1]}/${
                          oItem.createDateFormart1.split('-')[0]
                        }`}
                      </div>
                      <Box sx={{ marginInlineStart: 'auto' }}> {oItem.categoryName}</Box>
                    </Typography>
                    <Typography
                      variant='body2'
                      color='text.secondary'
                      sx={{
                        color: '#797a8d',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        '-webkit-line-clamp': '3',
                        '-webkit-box-orient': 'vertical',
                      }}
                    >
                      {getText(oItem.body)}
                    </Typography>
                  </CardContent>
                  <CardActions
                    sx={{
                      justifyContent: 'flex-end',
                      '.btn': {
                        color: '#095ef7',
                        position: 'absolute',
                        bottom: '12px',
                        fontWeight: 500,
                        left: direction === 'rtl' ? '20px' : 'unset',
                        right: direction === 'rtl' ? 'unset' : '20px',
                      },
                    }}
                  >
                    <Button
                      size='small'
                      type='text'
                      className='btn'
                      // onClick={() => {
                      //   navigate(`/blog/detail/${oItem.searchTitle.toLowerCase()}`, {
                      //     state: {
                      //       title: oItem.searchTitle.toLowerCase(),
                      //       id: oItem.id,
                      //     },
                      //   })
                      // }}
                    >
                      Read More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Empty
            blogs={dataBlog}
            locale={{
              ...pageLocale,
              searchEmpty: `${pageLocale.searchEmpty1} "${searhParams.titleOrBody}"<br />${pageLocale.searchEmpty2}`,
            }}
            titleOrBody={searhParams.titleOrBody}
          />
        )}
      </Box>

      {pageInfo?.totalPage > 0 && (
        <Stack
          spacing={2}
          sx={{
            mb: ['120px'],
            '.MuiPagination-ul': {
              justifyContent: 'center',
              '.Mui-selected': { color: '#095ef7' },
            },
          }}
        >
          <Pagination
            count={pageInfo?.totalPage}
            onChange={(e, page) => {
              setSearchParams({
                ...searhParams,
                currentPage: page,
              })
              getListBlogs.run({
                ...searhParams,
                currentPage: page,
              })
            }}
          />
        </Stack>
      )}
      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["blog"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
